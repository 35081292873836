/*
 * Flex UX Framework
 *
 * Filename: basket.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout {

    h1 {
        margin-top: 0;
    }

    main {
        background: #fff;
    }

    .page-body {
        max-width: 1175px;
        margin: 9px auto auto;
        padding: 12px;
        height: 100vh;
    }

    .page-header {
        height: 0;
    }
}

.checkout-basket {

   .checkout-container {
       display: flex;
       flex-wrap: wrap;

       @include mq('tablet', 'max') {
            margin: 10px;
       }

       @include mq('tablet', 'min') {
            margin: 20px;
       }

       .checkout-main {
            @include mq('tablet', 'max') {
                flex: 1 1 100%;
            }

            @include mq('tablet', 'min') {
                flex: 75 1;
                margin-right: 20px;
            }

            display: flex;
            flex-flow: column;

            .checkout-main-top {
                margin-bottom: 20px;

                .checkout-item {
                    display: flex;
                    white-space: nowrap;
                    border-bottom: 1px solid #efeff1;

                    .checkout-item-main {
                        display: flex;
                        align-items: center;
                        flex: 8 8 80%;
                        font-size: 12px;

                        @include mq('phone-wide', 'min') {
                            padding: 25px 20px 25px 25px;
                        }

                        .checkout-item-caption {
                            display: flex;
                            align-self: flex-start;
                            flex-direction: column;

                            @include mq('tablet', 'max') {
                                max-width: 60%;
                            }

                            &--title {
                               font-weight: 700;
                               margin-bottom: 3px;

                               @include mq('tablet', 'max') {
                                    word-wrap: normal;
                                    white-space: pre-line;
                               }
                            }

                            &--code {
                                @include mq('tablet', 'max') {
                                    word-wrap: normal;
                                    white-space: pre-line;
                                }
                            }
                        }

                        .delivery-options {
                            display: flex;
                            flex-flow: row;
                            flex-wrap: wrap;
                            white-space: normal;

                            li {
                                flex: 1 1 100%;
                                word-wrap: break-word;
                                height: auto;
                            }
                        }

                        a {
                            text-align: center;
                            // margin-right: 25px;
                        }

                        img {
                            @include mq('tablet', 'max') {
                                max-width: 75px;
                                max-height: 75px;
                                padding-left: 5px;
                            }

                            @include mq('tablet', 'min') {
                                max-width: 100px;
                                max-height: 100px;
                            }
                        }
                    }



                    .checkout-item-controls {
                        flex-grow: 2;
                        text-align: right;
                        box-sizing: border-box;

                        @include mq('tablet', 'max') {
                            padding: 10px;
                        }

                    }

                    &--control {
                        padding-top: 3px;
                        padding-bottom: 3px;
                        width: 100%;

                        .mdl-textfield {
                            padding: 0;
                        }

                        &__remove {
                            margin-top: 10px;
                            span {
                                text-decoration: none;
                                text-transform: uppercase;
                                font-weight: bold;
                            }
                        }
                    }

                    &--quantity {
                        max-width: 35px;
                    }

                    &--update-quantity {
                        cursor: pointer;
                        text-decoration: underline;
                        color: $brand-color;
                    }

                    &--remove {
                        cursor: pointer;
                    }

                } // checkout-item
            }
       } // .checkout-main
       .checkout-side {
            @include mq('tablet', 'max') {
                flex: 1 1 100%;
            }

             @include mq('tablet', 'min') {
                flex: 25 1;
             }  

            .checkout-side-top {
                flex-flow: column;
                padding: 0;

                 @include mq('tablet', 'max') {
                    min-height: 141px;
                 }

                 .checkout-basket-subtotals {
                    flex-flow: row;
                    border-bottom: 1px solid #efeff1;
                    display: flex;
                    flex: 1 1 100%;
                    font-size: 18px;
                       @include mq('tablet', 'max') {
                            max-height: 18px;
                            padding: 10px;
                       }

                       @include mq('tablet', 'min') {
                            padding: 20px;
                       }
                       .checkout-subtotals {
                           &--subtotal-label {
                               flex: 0 4 40%;
                           }
                           &--subtotal {
                               font-weight: bold;
                               flex: 0 6 60%;
                               text-align: right;
                           }
                       }
                 }
            }
        }

   } // .checkout-container

} // .checkout-basket

.checkout-basket-buttons {
    text-align: center;
    flex: 1 1 100%;
    font-size: 18px;

     @include mq('tablet', 'max') { 
        padding: 10px;
     }
     @include mq('tablet', 'min') { 
        padding: 20px;
     }

     button {
        @include mq('tablet', 'min') { 
            width: 250px;
        }
     }

     .btn-main {
        background: $brand-color;
        color: white;
        font-family: $standard-font-face;
        font-size: 10px;
        font-weight: bold;
        font-size: 14px;
        border-radius: 0;
        letter-spacing: 2px;
        padding: 0 24px;

         @include mq('tablet', 'max') { 
            min-height: 36px;
         }
     }

     .btn--primary {
        font-size: 16px;
        font-family: $standard-font-face;
        color: #fff;
        padding: 0px 15px;
        transition: background 0.15s;
        background-color: $brand-color;
        height: 45px;
        letter-spacing: 1px;
        font-weight: 700;
     }

     .btn--light {
        font-size: 16px;
        font-family: $standard-font-face;
        padding: 0px 15px;
        transition: background 0.15s;
        border: 1px solid #F2F2F2;
        background-color: #FFF;
        height: 45px;
     }

     .checkout-basket-taxwarning {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 20px;

         @include mq('tablet', 'max') { 
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
         }

         @include mq('tablet', 'min') { 
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 140px;
         }
     }

     .checkout-small-text {
        font-size: 12px;
        padding-bottom: 3px;
        padding-top: 3px;
    }
}    

