/*
 * Flex UX Framework
 *
 * Filename: category_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.sub-nav {

	.sub-nav-item {
    	position: relative;

	}

	.nav-handle {
		padding: 0 24px;
	}

}

.breadcrumb {
    a, span {
        color: #666;
        margin-right: 15px;
        text-decoration: none;
        z-index: 1;
    }
}

