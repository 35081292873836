/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background: $footer-background;
	color: #666;
	font-family: $standard-font-face;
	font-size: 12px;
	height: 25px;
	min-height: 25px;



	color: #FFFFFF;
	font-family: Arial;
	font-size: 15px;


	
	img {
		height: 44px;
		margin-right: 20px;
		display: none;

		@include mq("tablet", "max") {
		    height: 25px;
		    margin-bottom: 20px;
		    margin-right: 0px;
		}

	}

	a {
		color: #FFF;
		text-decoration: none;
		text-transform: capitalize;
		font-weight: normal;
		font-size: 15px;
		font-family: Ariel;
	}

	> div {
		display: flex;
		align-items: center;
		@include mq("tablet", "max") {
		    flex-direction: column;
		    justify-content: center;
		}
		@include mq("phone-wide", "max") {
    		height: 30px;
		}

	}

	@include mq("tablet", "max") {
		min-height: 105px;
		flex-flow: column nowrap;
		text-align: center;
		padding: 30px;
	}
}

