/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

 

$brand-color:                       #B95946;
$brand-color-secondary:             #C5B9AC;
$brand-color-btn-primary:           $brand-color;
$brand-color-btn-primary-hover:     rgba($brand-color,0.7);
$brand-color-btn-secondary:         $brand-color-secondary;
$brand-color-btn-secondary-hover:   rgba($brand-color-btn-secondary,0.7);// #606367;

$primary-font-color:                #000;
$h1-font-color:                     $primary-font-color;
$h2-font-color:                     $primary-font-color;


//
// General: Font
// 

$h1-font-size:                      34px;
$h2-font-size:                      21px;
$standard-font-face:             TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif; 
$drawer-font:                    TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif; 


//
// Header
//

$header-background:                 #444D55;; //$brand-color;
$header-height:                     150px;
$header-top-height:                 35px;
$header-main-height:                100px;
 
$header-tablet-height:              56px;
$header-phone-height:               56px;
$header-logo-padding:               10px 10px 10px 40px;
$header-minicart-badge-color:        #43807A;
$header-minicart-badge-border:      none; 
$header-top-link-hover-border:      2px solid rgba(255,255,255,0.5);
$header-icons-color:                #fff;
$header-search-font-color:          $header-icons-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #2c2f30; //$brand-color;


//
// Footer
//

$footer-background:                 #2c2f30;
