/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
 	main{
 		background-color: #ffffff;
	 	.spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					height: 100% !important;
				    min-height: unset !important;
				    max-height: unset !important;
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;
						}
					}
				}

				.hero-carousel.slick-initialized.slick-slider{
					@include mq("tablet", "max"){
						height: 287px !important;
					}	
				}

				.spotlight-hero{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					
					height: inherit;
					width: 287px;
					opacity: 0.95;
					padding: 40px 20px 20px;
					background-color: #53565A;
					color: white;
					font-size: 70px;

					@include mq("tablet", "max"){
						position:inherit;
					   height: 287px;
					   width: 100%;
					   opacity: 1;
					}

					@include mq("phone-wide", "max"){
						font-size: 60px;
					}

					&>div{
						display: flex;
						line-height: 0.8;
						&:first-of-type{
							font-size: 20px;
						}
					}

					button{
						// margin-top: 50px;
						color: white;
						background-color: $brand-color-btn-primary;
						min-height: 40px;
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

			#featured-1{
				.product-list{
					width: 101%;
					margin: 0px;
					background: #F2F3F3;
   					padding: 40px 0px 40px 0px;

					h1 {
						color: #000000;
						font-family: "Times New Roman";
						font-size: 34px;
						text-align: center;
						text-transform: capitalize !important;
						width: 100%;
						margin-bottom: 25px;
					}


					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 20%;
						min-width: 200px;
// 						margin: 12px;
					

						@include mq("tablet", "min"){ 
							max-width: 200px;
						}





						&:first-of-type {
							justify-content: space-evenly;
							&>h1{
								font-size: 34px !important;
							}
						}
						@include mq("phone-wide", "max"){
							&:nth-child(n+3){
								display: inherit;
							}
							&:nth-child(n+7){
								display: none;
							}

						}
					}
				}
			}

			.about-us{
				background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }