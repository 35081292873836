/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.category {
	
}

.category-list {
	main{
		background-color: #f2f2f2;
// 		height: 100vh;  
	}

	.page-header {
		background-color: #768692;
		display: flex;
		justify-content: space-evenly;
		padding-top: 150px;
		flex-direction: row;
		align-items: center; 
		height: 232px;
		padding-left: 20px;
    	padding-right: 24px;

		@include mq('tablet', 'max') {
			height: 0px;
			padding: 28px;
		}




    	&__img {

			width: 472px;
			min-width: 472px;

    	}

    	&__logo {

    		max-height: 150px; 

    	}

    	h1 {
			color: #FFFFFF;
			font-family: "Times New Roman";
			font-size: 65px;
			text-align: center;
			line-height: unset;
		}
	}

	.page-body {
		font-family: $standard-font-face;
		font-size: 14px;
		display: flex;
		flex-wrap: wrap;
		color: #000;

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			border-bottom: 1px solid rgba(226,226,226,0.5);
			order: 4;
			margin-right: -12px;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
				// flex-basis: 100%;
    			// margin: 24px -12px 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 6px -6px 0;
				padding: 18px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			flex-direction: row-reverse;
		}
		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
    	height: 41px;
    	padding-bottom: 5px;
    	margin-bottom: 15px;
    	
    	@include mq('tablet', 'max') {
			align-self: center;
			order: -1;
	    	flex: 2 2 50%;
	    	display: flex;
	    	justify-content: flex-end;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
    		flex-wrap: nowrap;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    	}

    	.title {
    		flex: 1 1 50%;
			font-size: 30px;
			height: 62px;
			font-weight: 400;
			font-family: $standard-font-face;

			@include mq('tablet', 'min') {
				line-height: 62px;
    			// margin-left: 240px;
			}
    	}

	}

}