/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// footer 

.footer-nav-menu {
	li {
		display: inline-block;
		list-style-type: none;
		font-size: 13px;
		margin-left: 13px;
    	margin-right: 13px;
		cursor: pointer;
		font-weight: 700;

		 box-sizing: border-box;
   		 border-bottom: 2px solid transparent;


		&:hover {
			box-sizing: border-box;
    		border-bottom: 2px solid rgba(255, 255, 255, 0.5);
		}
	}

	@include mq("tablet", "max") {
	    padding: 0;
	    padding-top: 25px;
	}
}