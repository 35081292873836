/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
    background: url("../../../images/spotlights/SAFEWORK_desktop_295_1024.png") no-repeat scroll center;
    background-image: url("../../../images/spotlights/SAFEWORK_desktop_295_1024.png");
    background-size: cover;
    
	.product-info {
    	text-align: center;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		max-width: 350px;
		margin: auto auto;
		align-self: flex-start;

		  @include mq("tablet-small", "max") {
		    margin: auto auto 0;
		  }
    }

 	> div {
	    width: 50%;
    	display: flex;
    	flex-flow: column nowrap;
		@include mq("tablet-small", "max"){
		    width: 100%;
		    height: 100%;
		}

    }

    h1 {
		color: #fff;
		font-family: $standard-font-face;
		text-shadow: 2px 2px 7px #333;
		font-size: 70px;
		line-height: 70px;

		@include mq("tablet", "max"){
			position: relative;
		    top: -8px;
		    font-size: 48px !important;
		    line-height: 43px;
		}

    	@include mq("desktop", "min"){
		    position: relative;
		    top: -8px;
		    font-size: 70px;
		    line-height: 70px;
		}
	}
}

body.home h1{
	margin: 0;
	
	@include mq("tablet", "max"){
    	font-size: 22px;
	}
	
}


.home .product-info--right {
  @include mq("tablet", "min"){
	    align-self: flex-end !important;
	    margin-right: 70px;
	}
}	


