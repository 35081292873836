/*

 * Flex UX Framework

 *

 * Filename: pagination.scss

 * Type:     Partial Stylesheet

 * 

 * Copyright (C) 2019 Geiger, All Rights Reserved

 */



.pagination{

	display: flex;

	justify-content:center;

	color: $brand-color;

	.pagination-left{

		display: flex;
 
	}

	.pagination-numbers{

		display: flex;

		list-style-type: none;

		margin: 0px;

		align-items: center;

		padding: 0px;



		li{

			padding: 0 2px;

			a{
				text-decoration: none;
			}

		}

	}

}