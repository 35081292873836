/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {
	.about-us {
    	margin: 12px 12px 32px 12px;

    	@include mq("phone-wide", "max"){
			margin: 6px;
		}
	}
 
	.welcome {

		display: flex;
		justify-content: center;
    	align-items: center;
    	padding: 15px;

    	@include mq("tablet", "max"){
			flex-direction: column;
		}

		h1 {
			padding: 0px 10px 10px 0px;
			color: #000000;
			font-family: "Times New Roman";
			font-size: 34px;
			text-align: center;
		}

		p {
			color: #000000;
			font-family: Arial;
			font-size: 14px;
			line-height: 21px;
			max-width: 488px;
			margin: 0;
			padding: 24px;
		}
	}

	.custom-orders {
		min-height: 233px;
		background-color: #8C8C8C;
	
		padding: 24px;
	

		&__container {

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 948px;
			margin: 0 auto;
			background-color: #FFFFFF;
			height: 100%;
			text-align: center;
			padding: 15px;
			flex: 1 0 auto;

			h1 {
				color: #000000;
				font-family: "Times New Roman";
				font-size: 34px;
				text-align: center;
				text-transform: capitalize !important;
			}
 
			p {
				color: #000000;
				font-family: Arial;
				font-size: 14px;
				line-height: 21px;
				max-width: 567px;
				padding: 10px;

				@include mq("phone-wide", "max"){
					max-width: 100%;
				}
			}

		}
	}
}