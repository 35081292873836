/*
 * Flex UX Framework
 *
 * Filename: register.scss
 * Type:     Template Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.register{
	.login-box .mdl-grid .mdl-textfield__label{
		text-align: center;
	}
}