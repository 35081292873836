/*
 * Flex UX Framework
 *
 * Filename: item-suggested-products.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.suggested-products {
	.suggested-products-header-container {
	    font-size: 24px;
	    font-family: $standard-font-face;
	    text-align: center;
	    padding: 30px 0px 20px 0px;
	

	    @include mq("tablet", "max") {
	    	text-align: center;
    		padding: 20px 0 14px;
	    }
	}

	.grid {
	    justify-content: center !important;
	}
}