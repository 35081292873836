/*
 * Flex UX Framework
 *
 * Filename: breadcrumbs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.breadcrumb {
    height: 42px;
    line-height: 42px;
    background: #FFF;
    font-size: 14px;
    font-weight: 400;
    font-family: Arial;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 158px;
    position: relative;

    span:last-of-type {
    	color: black;
		font-weight: 700;
    }

    @include mq("tablet", "max"){
        padding: 0 15px;
    }
}

.checkout{
    .page-body{ 
        h1{
            @include mq("tablet", "max"){
                font-size: 29px;
            }
        }

        @include mq("phone-wide", "max"){
            padding: 15px !important;
            margin-top: 0  !important;
        }
    }
}