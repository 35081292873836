/*
 * Flex UX Framework
 *
 * Filename: contact_us.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.contact-us {

	main {
		background: #fff;
	}

    .page-header {
        height: 0;
    }

	.page-body {
    	max-width: 1175px;
    	margin: auto;
    	margin-top: auto;
    	padding: 12px;
    	margin-top: 9px;
   		font-size: 14px;
    	line-height: 21px;
    	color: #666;
    	font-family: $standard-font-face;
	}
}