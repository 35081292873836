/*
 * Flex UX Framework
 *
 * Filename: item-images.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

	.product-image {

    	display: flex;
    	justify-content: center;
    	align-items: center;
    	flex: 1;
    	position: relative;

        &-container {
            width: 100%;
            background: white;
            padding: 20px 20px;
            box-sizing: border-box;
            border-radius: 5px;
            max-height: 520px;
            display: flex;

            @include mq("tablet", "max"){
                width: 100%;
                max-width: 500px;
                height: 20%;
            }

            .product-thumbnails {
           		width: 75px;
           		display: flex;
        		flex-direction: column;

        		.product-thumbnail {
                    border: 2px solid rgba(226,226,226,0.5);
                    height: 75px;
                    text-align: center;
                    overflow: hidden;

        			&.active {
        				border-color: $brand-color;
        			}

        			&:first-of-type {
        				box-shadow: none !important;
        			}
        		}
        	}

    	i {
    		&.material-icons {
    			font-size: 30px;
    			color: #9D9D9D;
				position: absolute;
				top: 0;
				left: 30px;
				z-index: 2;

                @include mq("tablet", "max"){
                   display: none;
                }
    		}
    	}
    }
}
