/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)
.about-us {
	background: white;
	border-radius: 5px;
	padding: 12px 30px;
	max-width: none;
	text-align: center;
	line-height: 21px;
	font-family: $standard-font-face;

	@include mq("phone-wide", "max"){
	    padding: 20px;
	}
	
	p{
		line-height: 21px;
	}

	.copy {
		margin: 20px 30px;
	}

	.title {
		padding-top: 10px;
		margin-top: -6px;
		padding-bottom: 0px;
		letter-spacing: 0;
		font-size: 34px;
		text-transform: none;
	}
}
