/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home {

 	main {
 		background: #f2f2f2;
 	}
 	
 	.page-body {
 		max-width: 972px;
 		margin:  9px auto auto;
 		padding: 12px;
 		background: inherit;

 		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
 	}
 }

 .homebanner {
	display: none !important;
	/* changed to 0 while banner is off site */
    margin-top: $header-height;
	height: 85px;
    background-color: #B41E24;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

	// @include mq("tablet", "max") {
	// 	margin-top: 56px;
 	// }

	@media (min-width: 920px) and (max-width: 1078px) {
		height: 120px;
		margin-top: 150px;
	}

	// @media (min-width: 760px) and (max-width: 920px) {
	// 	height: 120px;
	// 	margin-top: 150px;
	// }

	@include mq("desktop", "max") {
		height: 115px;
		// margin-top: 150px;
		margin-top: 56px;
	}

	@include mq("tablet-wide", "max") {
		height: 105px;
		margin-top: 56px;
	}

	@include mq("tablet-small", "max") {
		height: 110px;
	  }
	
	h1 {
		color: #FFF;
		font-size: 36px;

		@include mq("tablet-wide", "max") {
			font-size: calc(30px + 1vw);
		}

		@include mq("phone-wide", "max") {
			font-size: calc(18px + 1vw);
		}
		@include mq("tablet-small", "max") {
			font-size: calc(15px + 1vw);
		  }
	}

	p {
		color: #FFF;
		font-size: 22px;
		line-height: 30px;

		@include mq("tablet", "max") {
			font-size: calc(14px + 1vw);
			line-height: 24px;
		}

		// @include mq("tablet-small", "max") {
		// 	font-size: calc(16px + 1vw);
		// 	line-height: 18px;
		//   }

		  @include mq("phone-wide", "max") {
			font-size: calc(11px + 1vw);
			line-height: 16px;
		}
	}

	a {
		color:#FFF;
	}

	.bannerMug  {
		max-height: 67px;
		margin-right: 10px;
		margin-top: 7px;

		@media (min-width: 900px) and (max-width: 1078px) {
			margin-top: 26px;
			margin-left: 10px;
		}

		@include mq("tablet-wide", "max") {
			max-height: 55px;
			margin-top: 16px;
		}

		@include mq("phone-wide", "max") {
			max-height: 50px;
			margin-top: 22px;
		}
	}

	.bannerMittens  {
		max-height: 71px;
		margin-left: 10px;
		margin-top: 5px;

		@media (min-width: 900px) and (max-width: 1078px) {
			max-height: 60px;
			margin-top: 22px;
			margin-right: 10px;
		}

		@include mq("tablet-wide", "max") {
			max-height: 57px;
			margin-top: 18px;
		}

		@include mq("phone-wide", "max") {
			max-height: 53px;
			margin-top: 20px;
		}
	}
}
.homePageBanner {
    margin-top: $header-height;
	background-color: #43807A;
	height: 250px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
	align-items: center;

	// @media (min-width: 920px) and (max-width: 1078px) {
	// }

	@include mq("desktop", "max") {
		height: 180px;
	}
	@include mq("tablet", "max") {
		margin-top: 55px;
		max-width: 100%;
		width: auto;
	}

	p {
		color: #FFF;
		font-size: clamp(22px, 2.5vw, 35px);
		line-height: 1;
		padding: 0 10px;

		@include mq("tablet", "max") {
			font-size: calc(14px + 1vw);
			line-height: 24px;
		}

		  @include mq("phone-wide", "max") {
			font-size: calc(11px + 1vw);
			line-height: 16px;
		}
	}

	a {
		color:#FFF;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}

	.bannerEng,.bannerFrench  {
		max-height: 100% !important;
		
		@include mq("tablet", "max") {
		display: none;
		}
	}
}

.spotlight {
	display: flex;
	height: 528px;
	margin-top: $header-height;

	@include mq("tablet", "max") {
		   margin-top: 0px;
		   flex-direction: column;
	}
	@include mq("tablet", "max") {
		height: 700px;
	}
	@include mq("phone-wide", "max") {
		height: 749px;
	}



	.text {
		flex: 1;
		background-color: #768692;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 10px;

		@include mq("tablet", "max") {
			align-items: center;
		}

  

		h1 {
			color: #FFFFFF;
			font-family: "Times New Roman";
			font-size: 7vw;
			line-height: unset;
			padding-left: 5%;
			max-width: 556px;
			letter-spacing: -0.03em;

			@include mq("desktop-widest", "min") {
			  font-size: 133px;
			}

			@include mq("tablet-small", "max") {
			  font-size: 52px;
			}
 

		}

		h2 {
			color: #FFFFFF;
			font-family: Arial;
			font-size: 24px;
			padding-left: 5%;
			font-weight: normal;
			padding-top: 21px;

			@include mq("tablet-small", "max") {
			  font-size: 18px;
			  padding-top: 7px;
			}



		}
	}

// 	.categories:nth-child(even) {
// 		background-color: red;
// 	}

	.categories {
		flex: 2;
		background: lightgray;
		flex-wrap: wrap;
		flex-direction: row;
		display: flex;
		max-width: 880px;
		position: relative;

		@include mq("tablet", "max") {
		  max-width: none;
		}

			

		
  

		&__block {

			background-size: cover;
			display: flex;
			flex-direction: column;
			flex-basis: 50%;
			height: 50%;
			overflow: hidden;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				opacity: 0.8;
			}

			&:nth-of-type(1) {
				background-color: #C9CED2;
			}
			&:nth-of-type(2) {
				background-color: #A0ABB3;
			}
			&:nth-of-type(3) {
				background-color: #A0ABB3;
			}
			&:nth-of-type(4) {
				background-color: #C9CED2;
			}


			&__image {

				height: 100%;
				background-repeat: no-repeat !important;
				background-size: auto 100% !important;
    			background-position: 50% !important;
    			display: flex;
				justify-content: center;
				align-items: center;
				position: relative;




				 @include mq("tablet", "min") {
							left: 7%;
					}
    			

//     			 padding-left: 50%;

    			 h1 {
    			 	color: white;
					font-family: "Times New Roman";
					font-size: 60px;
					text-align: center;
					line-height: unset;
					width: 175px;
					line-height: unset;
					letter-spacing: -0.03em;

					 @include mq("tablet", "max") {
							font-size: 36px;
						}
 
    			 }
 

    		

    			 @include mq("tablet", "max") {
					padding-left: none;
				}


 
			
				&--apparel {
					background: url("../images/spot-apparel.png");
					max-width: 295px;
// 					margin-left: 10%;
				}

				&--office {
					background: url("../images/spot-office.png");
// 					max-width: 243px;
// 					margin-left: 24%;
				}

				&--ac {
					background: url("../images/spot-ac.png");
					max-width: 320px;
// 					margin-left: 10%;
				}

				&--redd {
					background: url("../images/spot-redd.png");
					max-width: 374px;
// 					margin-left: 6%;
				}

			}		

		}
 



	}


// 	.page-header {
// 		position: relative;
// 		padding-top: $header-height;

// 		@include mq("tablet", "max") {
//     		padding-top: 56px;
// 		}
// 	}

}